import React, { createContext, useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import reducer from '../reducer/userToken.reducer';
import { ActionType } from '../lib/enum/index';

const initialState = {
  token: null
};

const UserTokenContext = createContext();
const UserTokenDispatcherContext = createContext();

export const UserTokenProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { token: queryToken } = useParams();

  if (queryToken && !Object.values(state)[0]) {
    dispatch({ 
      type: ActionType.SET_TOKEN, 
      newToken: queryToken 
    });
  }

  return (
    <UserTokenContext.Provider value={state}>
      <UserTokenDispatcherContext.Provider value={dispatch}>{children}</UserTokenDispatcherContext.Provider>
    </UserTokenContext.Provider>
  );
}

export function useUserTokenContext() {
  return useContext(UserTokenContext);
}

export function useUserTokenDispatchContext() {
  return useContext(UserTokenDispatcherContext);
}

UserTokenProvider.propTypes = {
  children: PropTypes.shape({})
};

UserTokenProvider.defaultProps = {
  children: null
};
