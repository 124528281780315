import React from 'react';
import Default from '../../views/default/Default.view';
import BookingDetails from '../../views/bookingDetails/BookingDetails.view';
import { useAppState } from '../../hooks/useAppState';

function Dashboard() {
  const { appState } = useAppState();
  const { currentView } = appState ?? {};

  const VIEW_COMPONENTS = {
    default: <Default />,
    bookingDetails: <BookingDetails />
  };

  return VIEW_COMPONENTS[currentView];
}

export default Dashboard;
