import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { colors } from '../../layout/css';
import { colorWhite } from '../../layout/css/colors';

export const ButtonState = {
  default: colors.logoColor3,
  accessed: colors.logoColor2,
  disabled: colors.colorGrey03,
  clicked: colors.admin.midTheme
};

export const StyledDetailsButton = styledMUI(Button)((props) => ({
  color: colorWhite,
  backgroundColor: props.state ?? colors.logoColor3,
  '&:hover': {
    backgroundColor: props.state ?? colors.logoColor3,
    opacity: 0.5
  },
  marginTop: 5,
  marginBottom: 5,
  padding: 5,
  fontWeight: 'strong',
  fontSize: '14px',
  width: '120px'
}));

export const StyledH1 = styled.h1`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const LoadingStyle = { display: 'flex', justifyContent: 'center', m: 2, width: '100%', minHeight: '440px' };

export const TableLayoutStyle = { maxWidth: '100%', overflow: 'hidden', borderRadius: 0 };

export const OverflowStyle = {
  fontSize: '14px',
  wordWrap: 'break-word',
  width: '170px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};
